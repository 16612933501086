import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { theme } from '../site/styles';
import FooterSection from '@pageSections/common/footer';
import { navigate } from 'gatsby';
import WorkoutCard from '@components/WorkoutCardFullWidthNew';
import axios from 'axios';
import { IPrismicFeaturedWorkouts, IWorkout, PageContext } from '@appTypes';
import { logger } from '@utils';

import CallToActionSection from '@pageSections/common/callToAction';

const { colors, breakpoints } = theme;

export type FeaturedWorkoutDto = {
  _id: string;
  createdAt: Date;
  staticPreviewResourceUri: string;
  previewResourceUri: string;
  name: string;
  description: string;
  duration: number;
  shareLink: string;
  completionPercentage: number;
  averageRating: number;
  owner: {
    firstName: string;
    lastName: string;
  };
  tag?: string;
};

const FeaturedWorkouts: React.FC<PageProps<IPrismicFeaturedWorkouts, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData },
  pageContext,
  location,
}) => {
  const featuredWorkoutsData = prismicData!.edges[0].node.data;
  const [playingVideoIndex, setPlayingVideoIndex] = useState<string | null>(null);
  const [mostPopularWorkout, setMostPopularWorkout] = useState<IWorkout>();
  const [featuredWorkoutsItems, setFeaturedWorkoutsItems] = useState<IWorkout[]>();

  const { featured_workouts_enabled, page_subtitle, page_title, meta_title, meta_description, meta_image, meta_url } =
    featuredWorkoutsData;

  const { locale } = pageContext;

  useEffect(() => {
    if (!featured_workouts_enabled) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    getFeaturedWorkouts();
  }, []);

  const getFeaturedWorkouts = async () => {
    try {
      const { data }: { data: any } = await axios.get(`${process.env.GATSBY_CONTENT_API_URL}/workouts/featured`, {
        headers: {
          'X-Api-Key': process.env.GATSBY_CONTENT_API_KEY,
        },
      });
      if (Object.keys(data).length) {
        setMostPopularWorkout(data.data[0]);
        if (data.data.length > 1) {
          setFeaturedWorkoutsItems(data.data.slice(1));
        }
      }
    } catch (error) {
      logger.error('[getFeaturedWorkouts] ', error);
    }
  };

  const handleVideoPlay = (event: Event) => {
    const target = event.target as HTMLButtonElement;
    const cardElement = target.parentElement;
    const index = cardElement!.getAttribute('data-index');
    if (playingVideoIndex === index) {
      setPlayingVideoIndex(null);
    } else {
      setPlayingVideoIndex(index);
    }
  };

  return (
    <>
      <Container>
        <SEO
          title={meta_title}
          description={meta_description}
          image={meta_image.url}
          url={meta_url}
          pathname={location.pathname}
          name='Hyperhuman'
          locale={locale}
        />
        <TitleContainer>{page_title.text}</TitleContainer>
        <SubtitleContainer>{page_subtitle}</SubtitleContainer>
        <Body>
          {mostPopularWorkout && (
            <WorkoutCard
              playingVideoIndex={playingVideoIndex}
              index={999}
              workout={mostPopularWorkout}
              onVideoPlay={handleVideoPlay}
            ></WorkoutCard>
          )}
          <WorkoutsList>
            {featuredWorkoutsItems &&
              featuredWorkoutsItems.map((workout, index) => {
                return (
                  <WorkoutCard
                    key={index}
                    playingVideoIndex={playingVideoIndex}
                    index={index}
                    workout={workout}
                    onVideoPlay={handleVideoPlay}
                  ></WorkoutCard>
                );
              })}
          </WorkoutsList>
        </Body>
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} />
    </>
  );
};

export default FeaturedWorkouts;

const Body = styled.div``;

const WorkoutsList = styled.div`
  margin-top: 56px;
  margin-bottom: 80px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 0 auto;
    margin-top: 88px;
    margin-bottom: 0px;
  }
`;

const TitleContainer = styled.h1`
  font-family: 'Poppins';
  color: ${colors.outerSpace};
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: -0.3px;
  margin-top: 70px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 40px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: -0.2px;
    margin-top: 100px;
    text-align: center;
    margin-bottom: 30px;
  }
`;

const SubtitleContainer = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${colors.grannySmith};
  margin-bottom: 56px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 22px;
    text-align: center;
    line-height: 30px;
    font-weight: 400;
    max-width: 557px;
    margin: 0 auto;
    margin-bottom: 96px;
  }
`;

const Container = styled.div`
  margin-top: 34px;
  margin-bottom: 26px;
  padding: 0 16px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
    padding: 0 24px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
    margin-bottom: 130px;
  }
`;

export const pageQuery = graphql`
  query FeaturedWorkoutsQuery($locale: String!) {
    prismicData: allPrismicFeaturedWorkouts(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            featured_workouts_enabled
            meta_title
            meta_description
            meta_image {
              url
            }
            meta_url
            meta_keywords
            page_subtitle
            page_title {
              text
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
